body { background-color: #ffffff; }
body { color: var(--clr-1903); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-1904);
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-1904);
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.1;
text-transform: uppercase;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: var(--clr-1904);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-1906);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: var(--clr-1906);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-1906);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: var(--clr-1906);
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 10;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

}
summary {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
p { padding-bottom: 1em; }
a {color: var(--clr-1904);}
a:hover {color: var(--clr-1905);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-1904);
&:hover {background-color: transparent;}
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-1904);
color: #ffffff;
border-width: 0 0 4px 0;
border-style: solid;
border-color: var(--clr-1907);
 }
.MES3 {
background-color: var(--clr-1904);
color: #ffffff;
border-width: 0 0 4px 0;
border-style: solid;
border-color: var(--clr-1907);
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
a.MEC3 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC3{
color: #ffffff;
}
/* Main Menu:4 */
nav.me-Menu.MES4 {
& .menu-item.MEC4, & .menu-item.MEC4 > div.MEC4{ & > a.MEC4{color: #ffffff;
text-transform: uppercase;
}
 &:hover > a.MEC4{color: var(--clr-1908);
}
 }
&.horizontal > .menu-item.MEC4 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC4 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC4 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 18;
border-style: solid;
 }
&.horizontal > .menu-item.MEC4 .sub-menu { border:1px;
border-color: 18;
border-style: solid;
 }
& > .menu-item > a{padding: 10px 10px 33px 10px;}

& .sub-menu .menu-item a{padding: 15px;}



&.horizontal .menu-item.MEC4:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-1907-flat);}}
&.vertical .menu-item.MEC4:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-1907-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC4{background-color: var(--clr-1907); &:hover {background-color: 12;}
}
& .menu-item.MEC4, & .menu-item.MEC4 > div.MEC4{ & > a.MEC4{color: #ffffff;
text-transform: uppercase;
}
 &:hover > a.MEC4{color: 2;
}
 }

}
}
 }
/* Header Scroll:5 */
.MES5 {
background-color: var(--clr-1904);
color: var(--clr-1905);
border-width: 0 0 5px 0;
border-style: solid;
border-color: transparent transparent var(--clr-1908) transparent;
 }
.MES5 {
background-color: var(--clr-1904);
color: var(--clr-1905);
border-width: 0 0 5px 0;
border-style: solid;
border-color: transparent transparent var(--clr-1908) transparent;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
a.MEC5 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC5{
color: var(--clr-1905);
}
/* Header:6 */
.MES6 {
background-color: var(--clr-1909);
 }
.MES6 {
background-color: var(--clr-1909);
 }
a.MEC6 { color: var(--clr-1904);
&:hover { color: var(--clr-1905);}
 }
/* Footer:7 */
.MES7 {
background-color: var(--clr-1904);
color: #ffffff;
font-size: 9.8px;
@media #{$medium-up} {
font-size: 11.2px;
};
padding: 20px 15px;

 }
.MES7 {
background-color: var(--clr-1904);
color: #ffffff;
font-size: 9.8px;
@media #{$medium-up} {
font-size: 11.2px;
};
padding: 20px 15px;

 }
a.MEC7 { color: #ffffff;
&:hover { color: var(--clr-1905);}
 }
.MEC7 li {color: #ffffff;}
cite.MEC7{
color: #ffffff;
font-size: 9.8px;
@media #{$medium-up} {
font-size: 11.2px;
};
}
/* Home Banner Slider:8 */
.MES8 {
& .slider-arrow {color: var(--clr-1909);
font-size: 50px;
@media #{$medium-up} {
font-size: 80px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slider-arrow:hover{color: var(--clr-1910);
} }
/* Home Grey Panel:9 */
.MES9 {
background-color: var(--clr-1908);
padding: 20px 15px;

@media #{$medium-up} {
padding: 60px 15px;

}
 }
.MES9 {
background-color: var(--clr-1908);
padding: 20px 15px;

@media #{$medium-up} {
padding: 60px 15px;

}
 }
/* Primary:10 */
.MES10 {
background-color: var(--clr-1904);
&:hover {background-color: var(--clr-1906);}
color: #ffffff;
&:hover { color: #ffffff;}
padding: 15px 30px;

 }
/* Slider Overlay:11 */
.MES11 {
background-color: var(--clr-1909);
color: #ffffff;
padding: 20px;

 }
.MES11 {
background-color: var(--clr-1909);
color: #ffffff;
padding: 20px;

h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: #ffffff;
 }
 }
cite.MEC11{
color: #ffffff;
}
/* footer divider:12 */
.MES12 {
& > hr {border-width: 0 0 1px 0;}
& > hr {border-color: transparent transparent var(--clr-1910) transparent;}
& > hr {border-bottom-style: solid;}
 }
/* Parallax:13 */
.MES13 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.72 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://thirdangle.com.au/img/2331/53');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
 }
.MES13 {
background-color: #000000;
& > .underlay, & > .inner-overlay { opacity: 0.72 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://thirdangle.com.au/img/2331/53');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
min-height:300px;
@media #{$medium-up} {
min-height: 400px;};
@media #{$large-up} {
min-height: 500px;};
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
cite.MEC13{
color: #ffffff;
}
/* Hollow:14 */
.MES14 {
background-color: transparent;
&:hover {background-color: transparent;}
color: #ffffff;
&:hover { color: #ffffff;}
padding: 10px 30px;

@media #{$medium-up} {
padding: 10px 50px;

}
border-width: 1px;
border-style: solid;
border-color: #ffffff;
 }
/* Form Panel:16 */
.MES16 {
background-color: var(--clr-1908);
color: var(--clr-1906);
padding: 15px;

 }
.MES16 {
background-color: var(--clr-1908);
color: var(--clr-1906);
padding: 15px;

h1.MEC16, h2.MEC16, h3.MEC16, h4.MEC16, h5.MEC16, h6.MEC16 { color: var(--clr-1906);
 }
 }
cite.MEC16{
color: var(--clr-1906);
}
/* Title divider:17 */
.MES17 {
& > hr {border-width: 0 0 3px 0;}
& > hr {border-color: transparent transparent var(--clr-1904) transparent;}
& > hr {border-bottom-style: solid;}
 }
/* Slider Image overlay:18 */
.MES18 {
background-color: var(--clr-1911);
color: var(--clr-1905);
font-size: 14px;
@media #{$medium-up} {
font-size: 19.2px;
};
 }
.MES18 {
background-color: var(--clr-1911);
color: var(--clr-1905);
font-size: 14px;
@media #{$medium-up} {
font-size: 19.2px;
};
h1.MEC18, h2.MEC18, h3.MEC18, h4.MEC18, h5.MEC18, h6.MEC18 { color: #ffffff;
 }
h1.MEC18 { @media #{$medium-up} { font-size: 48px; }; }
h2.MEC18 { @media #{$medium-up} { font-size: 42px; }; }
h3.MEC18 { @media #{$medium-up} { font-size: 36px; }; }
h4.MEC18 { @media #{$medium-up} { font-size: 30px; }; }
h5.MEC18 { @media #{$medium-up} { font-size: 24px; }; }
h6.MEC18 { @media #{$medium-up} { font-size: 21.6px; }; }
 }
cite.MEC18{
color: var(--clr-1905);
font-size: 14px;
@media #{$medium-up} {
font-size: 19.2px;
};
}
/* Menu Scroll:19 */
nav.me-Menu.MES19 {
& .menu-item.MEC19, & .menu-item.MEC19 > div.MEC19{ & > a.MEC19{color: var(--clr-1912);
text-transform: capitalize;
}
 &:hover > a.MEC19{color: var(--clr-1905);
}
 }
&.horizontal > .menu-item.MEC19 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC19 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC19 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 18;
border-style: solid;
 }
&.horizontal > .menu-item.MEC19 .sub-menu { border:1px;
border-color: 18;
border-style: solid;
 }
& > .menu-item > a{padding: 10px 10px 33px 10px;}

& .sub-menu .menu-item a{padding: 5px 10px;}

& > .menu-item.MEC19.active { color: var(--clr-1907); }
&.horizontal .menu-item.MEC19:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid #ffffff;}}
&.vertical .menu-item.MEC19:hover {& > .pointer-wrap > .pointer{border-right: 10px solid #ffffff;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC19{background-color: #ffffff; &:hover {background-color: 12;}
}
& .menu-item.MEC19, & .menu-item.MEC19 > div.MEC19{ & > a.MEC19{color: var(--clr-1906);
text-transform: capitalize;
}
 &:hover > a.MEC19{color: 2;
}
 }

}
}
 }
/* Secondary:20 */
.MES20 {
background-color: var(--clr-1905);
color: #ffffff;
&:hover { color: #ffffff;}
 }
/* Secondary:21 */
.MES21 {
background-color: var(--clr-1905);
color: #ffffff;
 }
.MES21 {
background-color: var(--clr-1905);
color: #ffffff;
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: #ffffff;
 }
 }
cite.MEC21{
color: #ffffff;
}
/* Home Blocks:22 */
.MES22 {
border-width: 1px;
border-style: solid;
border-color: var(--clr-1908);
 }
.MES22 {
border-width: 1px;
border-style: solid;
border-color: var(--clr-1908);
 }
/* Dark:23 */
.MES23 {
background-color: var(--clr-1904);
 }
/* Dark:24 */
.MES24 {
background-color: var(--clr-1904);
 }
.MES24 {
background-color: var(--clr-1904);
 }
/* Header White Scroll:25 */
.MES25 {
background-color: #ffffff;
color: var(--clr-1905);
border-width: 0 0 5px 0;
border-style: solid;
border-color: transparent transparent var(--clr-1908) transparent;
 }
.MES25 {
background-color: #ffffff;
color: var(--clr-1905);
border-width: 0 0 5px 0;
border-style: solid;
border-color: transparent transparent var(--clr-1908) transparent;
h1.MEC25, h2.MEC25, h3.MEC25, h4.MEC25, h5.MEC25, h6.MEC25 { color: #ffffff;
 }
 }
a.MEC25 { color: var(--clr-1904);
&:hover { color: var(--clr-1905);}
 }
cite.MEC25{
color: var(--clr-1905);
}
/* Light Grey Panel:26 */
.MES26 {
background-color: var(--clr-1913);
padding: 20px 15px;

@media #{$medium-up} {
padding: 60px 15px;

}
 }
.MES26 {
background-color: var(--clr-1913);
padding: 20px 15px;

@media #{$medium-up} {
padding: 60px 15px;

}
 }
/* White Panel:27 */
.MES27 {
background-color: #ffffff;
padding: 20px 15px;

@media #{$medium-up} {
padding: 60px 15px;

}
 }
.MES27 {
background-color: #ffffff;
padding: 20px 15px;

@media #{$medium-up} {
padding: 60px 15px;

}
 }
/* Slider Overlay 2:29 */
.MES29 {
background-color: var(--clr-1914);
color: #ffffff;
padding: 20px;

 }
.MES29 {
background-color: var(--clr-1914);
color: #ffffff;
padding: 20px;

h1.MEC29, h2.MEC29, h3.MEC29, h4.MEC29, h5.MEC29, h6.MEC29 { color: #ffffff;
 }
 }
cite.MEC29{
color: #ffffff;
}
/* READ MORE:30 */
.MES30 {
 }
/* Slider Panel:31 */
.MES31 {
background-color: var(--clr-1910);
 }
.MES31 {
background-color: var(--clr-1910);
 }
/* Responsive Menu:32 */
nav.responsive-menu {
.menu-item.MEC32{background-color: var(--clr-1904);}
& .menu-item.MEC32, & .menu-item.MEC32 > div.menu-item-wrap{ & > a.MEC32, & > i{color: #ffffff;
text-align: left;
}
  }
& .menu-item.MEC32 { border:0;
border-color: #ffffff;border-style: solid;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 10px;}

& .sub-menu{& .menu-item.MEC32, & .menu-item.MEC32 > div.menu-item-wrap{ & > a.MEC32, & > i{color: 2;
text-align: left;
}
  }
 .sub-menu {& .menu-item.MEC32, & .menu-item.MEC32 > div.menu-item-wrap{ & > a.MEC32, & > i{color: 17;
text-align: left;
}
  }
}}

 }
/* Responsive Menu:33 */
nav.responsive-menu {
.menu-item.MEC33{background-color: var(--clr-1904);}
& .menu-item.MEC33, & .menu-item.MEC33 > div.menu-item-wrap{ & > a.MEC33, & > i{color: #ffffff;
text-align: left;
}
  }
& .menu-item.MEC33 { border:0;
border-color: #ffffff;border-style: solid;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 10px;}

& .sub-menu{.menu-item.MEC33{background-color: 24;}
& .menu-item.MEC33, & .menu-item.MEC33 > div.menu-item-wrap{ & > a.MEC33, & > i{color: 2;
text-align: left;
}
  }
 .sub-menu {.menu-item.MEC33{background-color: 18;}
& .menu-item.MEC33, & .menu-item.MEC33 > div.menu-item-wrap{ & > a.MEC33, & > i{color: 17;
text-align: left;
}
  }
}}

 }
/* button 2:34 */
.MES34 {
background-color: var(--clr-1905);
&:hover {background-color: var(--clr-1906);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 20px;
padding: 15px 30px;

border-width: 4px;
border-style: solid;
border-color: var(--clr-1906);
 }
/* :35 */
.MES35 {
background-color: var(--clr-1910);
border-width: 0 0 4px 0;
border-style: solid;
border-color: var(--clr-1910);
 }
.MES35 {
background-color: var(--clr-1910);
border-width: 0 0 4px 0;
border-style: solid;
border-color: var(--clr-1910);
 }
/* Form Button:36 */
.MES36 {
background-color: #ffffff;
&:hover {background-color: var(--clr-1906);}
color: #000000;
&:hover { color: #ffffff;}
padding: 15px;

 }
/* White bg image:37 */
.MES37 {
background-color: #ffffff;
padding: 5px;

 }
.MES37 {
background-color: #ffffff;
padding: 5px;

 }
